import React, { Component } from 'react';
import {
  //BrowserRouter as Router,
  Router,
  Switch,
  Route,
  Link,
  Redirect
} from "react-router-dom";
import ScrollIntoView from "./ScrollIntoView";
import { createBrowserHistory } from "history";
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';

import Login from './Auth/Login';
import Logout from './Auth/Logout';
import Home from './Home/Home';
import PrivacyPolicy from './Static/PrivacyPolicy';
import RefundPolicy from './Static/RefundPolicy';
import TermsConditions from './Static/TermsConditions';
import ContactUs from './Static/ContactUs';
import PricePlans from './Static/PricePlans';
import Payment from './Payment/Payment';
import PaymentReturn from './Payment/PaymentReturn';

//var Moengage = require('./utils.js').Moengage;
var extractUrlParamSignup = require('./utils.js').extractUrlParamSignup;

const history = createBrowserHistory();
ReactGA.initialize('UA-217814097-1');
ReactGA.pageview(window.location.pathname);
console.log(window.location.pathname);
history.listen((location, action) => {
    ReactGA.pageview(location.pathname + location.search);
    console.log(location.pathname);
});

/*Facebook Pixel */

//const advancedMatching = { em: '' }; 
const options = {
  autoConfig: true, // set pixel's autoConfig. 
  debug: false, // enable logs
};
ReactPixel.init('326933222658881', options);
ReactPixel.pageView(); // For tracking page view

class Routes extends Component {

  	constructor(props) {
	  	super(props);
	  	let first_signup = 0;
	  	//var first_signup_url = extractUrlParamSignup();
	    //if(first_signup_url){
	    	//first_signup = first_signup_url;
	    //}
	    this.state = {
	      access_token: localStorage.getItem("student_token"),
	      student_id: localStorage.getItem("student_id"),
	      redirect_to_login: false,
	      first_signup: first_signup,
	      student: {id: null, first_name: null, last_name: null, dob: null, mobile_number: null, email: null, avatar_url: "/student.png", school_id: null, grade: null, section: null, created_at: null}
	    };
	    this.updateToken = this.updateToken.bind(this);
	    this.updateTokenWithRedirect = this.updateTokenWithRedirect.bind(this);
  	}

  	updateTokenWithRedirect() {
	    this.setState({
	      access_token: localStorage.getItem("student_token"),
	      student_id: localStorage.getItem("student_id")
	    });
	    if(this.state.access_token===null || this.state.student_id===null ){
	    	/*this.setState({
		        redirect_to_login: true
		    });*/
	    }
	}

  	updateToken() {
	    this.setState({
	      access_token: localStorage.getItem("student_token"),
	      student_id: localStorage.getItem("student_id")
	    });
	    if(this.state.access_token===null || this.state.student_id===null ){
	    	/*this.setState({
		        redirect_to_login: true
		    });*/
	    }
	}

  	componentDidMount(){
  	}

  	render(){

	  	return (<Router history={history}>
	  			{this.state.redirect_to_login ? (
	  				<Switch>
	  		         	<Route path="/login" render={(props) => <Login update_token={this.updateTokenWithRedirect} />}  />
	  		         	<Route render={() => (<Redirect to="/login" />)}/>
	  		        </Switch>
	  		    ) : (
		  		       <ScrollIntoView>
		  		        <Switch>
		  		          <Route path="/login" render={(props) => <Login update_token={this.updateTokenWithRedirect} />}  />
		  		          <Route path="/logout" render={(props) => <Logout update_token={this.updateTokenWithRedirect} />}  />
		  		          <Route path="/privacy-policy" render={(props) => <PrivacyPolicy {...props} update_token={this.updateToken} current_route="courses" access_token={this.state.access_token} />} />
		  		          <Route path="/refund-policy" render={(props) => <RefundPolicy {...props} update_token={this.updateToken} current_route="courses" access_token={this.state.access_token} />} />
		  		          <Route path="/terms-and-conditions" render={(props) => <TermsConditions {...props} update_token={this.updateToken} current_route="courses" access_token={this.state.access_token} />} />
		  		          <Route path="/contact-us" render={(props) => <ContactUs {...props} update_token={this.updateToken} current_route="courses" access_token={this.state.access_token} />} />
		  		          <Route path="/price-plans" render={(props) => <PricePlans {...props} update_token={this.updateToken} current_route="courses" access_token={this.state.access_token} />} />
		  		          <Route path="/payment" render={(props) => <Payment {...props} update_token={this.updateToken} current_route="webinars" access_token={this.state.access_token} />} />
		  		          <Route path="/orders/success" render={(props) => <PaymentReturn {...props} update_token={this.updateToken} current_route="webinars" access_token={this.state.access_token} />} />
		  		          <Route path="/" render={(props) => <Home {...props} update_token={this.updateToken} current_route="webinars" access_token={this.state.access_token} />} />
				        </Switch>
				        </ScrollIntoView>
		  		)}
	  			</Router>);
  	}
}


export default Routes;
