import React, { Component } from 'react';
import {
  Link
} from "react-router-dom";
import ReactDOM from 'react-dom';

import { Redirect } from 'react-router';
import swal from 'sweetalert';
import Header from '../Header';
import EnterMobile from './EnterMobile';
import EnterOTP from './EnterOTP';

import Footer from '../Footer';

//var Moengage = require('../utils.js').Moengage;

class LoginPopup extends Component {

	constructor(props) {
	  	super(props);
	    this.state = {
	      showPhone: true,
	      mob: 0,
	      redirect: false, //redirects after login,
	      first_time_user: 0
	    };
	    this.mobHandler = this.mobHandler.bind(this);
	    this.otpHandler = this.otpHandler.bind(this);
	}

	mobHandler(mob){

		var myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");
		var raw = JSON.stringify({"phone_number":mob});

		var requestOptions = {
		  method: 'POST',
		  headers: myHeaders,
		  body: raw,
		  redirect: 'follow'
		};

		fetch(`${process.env.REACT_APP_BASE_URL}/auth/students/sendotp`, requestOptions)
		  	.then((response) => {
			    if(response.ok) {
			    	return response.json();
			    }else{
			    	throw new Error('Something went wrong. Please contact support.');
			    	return Promise.reject();
			    }
			})
	        .then(
	          (result) => {
	          	//alert(result.success);
	          	this.setState({
			      showPhone: false,
			      mob: mob,
			      first_time_user: result.data.first_time_user
			    });
	          }
      		).catch(
      			(error) => {
      				console.log(error);
      			}
      		);
	}

	otpHandler(otp) {
		var myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");
		
		var raw = JSON.stringify({"phone_number":this.state.mob,"otp":otp});

		var requestOptions = {
		  method: 'POST',
		  headers: myHeaders,
		  body: raw,
		  redirect: 'follow'
		};

		fetch(`${process.env.REACT_APP_BASE_URL}/auth/students/verifyotp`, requestOptions)
		  	.then(function(response) {
		  		let status = response.status;
		  		let json_response = response.json();
			    if (status != 200) {
			    	swal("OTP doesn't seem to be right. Please recheck.");
			        if(status === 400 || status === 404){
			        	console.log(json_response);
			        	throw new Error('Something went wrong. Please contact support.');
			        }else{
			        	throw new Error('Something went wrong. Please contact support.');
			        }
			    }else{
			    	return json_response;
			    }
			    
			})
	        .then(
	          (result) => {
	          	localStorage.setItem('student_id', result.data.user_id);
	          	localStorage.setItem('student_token', result.data.token);
	          	window.Moengage.add_unique_user_id(result.data.user_id);
	          	this.props.update_token();
	          	swal.close();
	          	if(this.state.first_time_user===1){
	          		var swal_profile_popup = document.createElement('div');
		            ReactDOM.render(
		              <React.StrictMode>
		              </React.StrictMode>,
		              swal_profile_popup
		            );
		            swal({
		                "content": swal_profile_popup,
		                "buttons": false,
		                "className": "swal-login",
		                "closeOnClickOutside": false
		            });
	          	}else{
	          		window.location.reload();
	          	}
	          },
	          (error) => {
	          	//alert(error);
	          }
      		);
	}

    closePopup(){
        swal.close();
    }

	render(){
		return(
			<div>
				<div className="row login-page-popup">
					<div className="login-box-popup col-sm-12 col-md-12 col-lg-12 col-xs-12">
						<img className="login-popup-cross-icon" src="/icons/cross_black.png" onClick={() => this.closePopup()} />
						<img src="/edvolve_full.png" className="edvolve-full-logo" />
						<h1>Sign up/Sign in</h1>
						<h2>Industry leaders. Organisations. Brands. All in one place to help you fuel your passion and aptitude.</h2>
						{ this.state.showPhone ? <EnterMobile mob_handler={this.mobHandler} /> : <EnterOTP otp_handler={this.otpHandler} mob_number={this.state.mob} first_time_user={this.state.first_time_user} /> }
					</div>
	            </div>
            </div>
			);
	}
}


export default LoginPopup;
