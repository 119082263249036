import React, { Component } from 'react';
import OtpInput from 'react-otp-input';
import Timer from 'react-compound-timer';

class EnterOTP extends Component {

	constructor(props) {
	  	super(props);
	    this.state = {
	      otp: "",
	      show_timer: true,
	      no_resend_option: false,
	      first_time_user: this.props.first_time_user
	    };
	    this.handleChange = this.handleChange.bind(this);
	    this.otpSubmit = this.otpSubmit.bind(this);
	    this.resendOTP = this.resendOTP.bind(this);
	    this.showResend = this.showResend.bind(this);
	}

	handleChange(otp) {
		if(otp.length===6){
			document.getElementById('otp_number_btn'). style. backgroundColor = '#1D9929';
		}else{
			document.getElementById('otp_number_btn'). style. backgroundColor = '#717171';
		}
	    this.setState({
	      otp: otp
	    })
	}

	otpSubmit() {
	    this.props.otp_handler(this.state.otp); 
	}

	showResend() {
	    this.setState({
	      show_timer: false
	    });
	}

	resendOTP(){
		var myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");
		var raw = JSON.stringify({"phone":this.props.mob_number});

		var requestOptions = {
		  method: 'POST',
		  headers: myHeaders,
		  body: raw,
		  redirect: 'follow'
		};

		fetch(`https://dashboard.sosschoolofskills.com/send-otp`, requestOptions)
		  	.then((response) => {
			    if(response.ok) {
			    	return response.json();
			    }else{
			    	throw new Error('Something went wrong. Please contact support.');
			    	return Promise.reject();
			    }
			})
	        .then(
	          (result) => {
	          	this.setState({
			      no_resend_option: true
			    });
	          }
      		).catch(
      			(error) => {
      				console.log(error);
      			}
      		);
	}

	render(){
		//alert(this.state.first_time_user);
		return(
			<div id="enter_otp" className="login-detail-box col-sm-12 col-md-12 col-lg-12 col-xs-12">
				<h2>Enter 6 digit OTP</h2>
				<OtpInput value={this.state.otp} onChange={this.handleChange} numInputs={6} separator={<span>-</span>} isInputNum={true} containerStyle="otp-box" inputStyle="partitioned-input" />
				{ this.state.no_resend_option ? (<div></div>) : this.state.show_timer ? (
				<div className="otp-timer-div">
					<Timer initialTime={60000} lastUnit="s" direction="backward" checkpoints={[{time: 0,callback: () => this.showResend()}]}>
						Resend OTP in <Timer.Seconds /> seconds.
					</Timer>
				</div>
				):(
				<div className="resend-otp-text" onClick={this.resendOTP}>
					Resend OTP
				</div>
				)
				}
				{ this.state.first_time_user === 0 ? (<div></div>) : (
				<p>By signing up, you agree to the <a href="/privacy-policy" target="_blank">privacy policy</a> set by SoS - School of Skills.</p>
				)}
				<input id="otp_number_btn" type="button" value="Confirm OTP" className="login-btn" onClick={this.otpSubmit} />
			</div>
			);
	}
}

export default EnterOTP;