import React, { Component } from 'react';

class EnterMobile extends Component {

	constructor(props) {
	  	super(props);
	    this.state = {
	      mob: 0
	    };
	    this.handleChange = this.handleChange.bind(this);
	    this.mobNumber = this.mobNumber.bind(this);
	}

	handleChange(mob) {
		if(mob.target.value.length===10){
			document.getElementById('mob_number_btn'). style. backgroundColor = '#1D9929';
		}else{
			document.getElementById('mob_number_btn'). style. backgroundColor = '#717171';
		}
	    this.setState({
	      mob: mob.target.value
	    })
	}

	mobNumber() {
	    this.props.mob_handler(this.state.mob); 
	}

	render(){
		return(
			<div className="login-detail-box col-sm-12 col-md-12 col-lg-12 col-xs-12">
				<h2>Enter your mobile number</h2>
				<input onChange={this.handleChange} type="tel" name="mobile" className="mobile-login" placeholder="Your 10 digit number" minLength={10} maxLength={10} />
				<input id="mob_number_btn" type="button" value="Get OTP" className="login-btn" onClick={this.mobNumber} />
			</div>
			);
	}
}


export default EnterMobile;