
var dateConverter = function(start_time){
    let current_epoch = Math.floor(Date.now() / 1000);
    let full_date = "";
    if(current_epoch<start_time){
        full_date = upcomingDateTime(start_time);
    }else{
        full_date = pastDateTime(current_epoch, start_time);
    }
    return full_date;
}

var upcomingDateTime = function(start_time) {
    var date = new Date(0); // The 0 there is the key, which sets the date to the epoch
    date.setUTCSeconds(start_time);
    let month_array = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    let month_num = date.getMonth();
    let month = month_array[month_num];
    let day = date.getDate();
    let year = date.getFullYear();
    let strTime = timeConverter(date);

    let full_date = strTime + ", " + day + " " + month + ", " + year;
    return full_date;
}

function findGetParameter(parameterName) {
    var result = null,
        tmp = [];
    var items = window.location.search.substr(1).split("&");
    for (var index = 0; index < items.length; index++) {
        tmp = items[index].split("=");
        if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
    }
    return result;
}

var scheduleStartTime = function(start_time) {
    var date = new Date(0); // The 0 there is the key, which sets the date to the epoch
    date.setUTCSeconds(start_time);
    let month_array = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    let month_num = date.getMonth();
    let month = month_array[month_num];
    let day = date.getDate();
    let strTime = timeConverter(date);

    let full_date = day + " " + month + ", " + strTime;
    return full_date;
}

var cancellationTime = function(start_time) {
    var date = new Date(0); // The 0 there is the key, which sets the date to the epoch
    start_time = start_time - 172800; //reducing two days
    date.setUTCSeconds(start_time);
    //date = date - 172800; //reducing two days
    let month_array = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    let month_num = date.getMonth();
    let month = month_array[month_num];
    let day = date.getDate();
    //let strTime = timeConverter(date);

    let full_date = day + " " + month;
    return full_date;
}

var dateStartTime = function(start_time) {
    var date = new Date(0); // The 0 there is the key, which sets the date to the epoch
    date.setUTCSeconds(start_time);
    let month_array = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    let month_num = date.getMonth();
    let month = month_array[month_num];
    let day = date.getDate();

    let full_date = day + " " + month;
    return full_date;
}

var dayWeekTime = function(start_time) {
    var date = new Date(0); // The 0 there is the key, which sets the date to the epoch
    date.setUTCSeconds(start_time);
    let week_array = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    let week_num = date.getDay();
    let week = week_array[week_num];

    let full_date = week;
    return full_date;
}

var timeRange = function(start_time, end_time) {
    var end_date = new Date(0); // The 0 there is the key, which sets the date to the epoch
    end_date.setUTCSeconds(end_time);
    let endStrTime = timeConverter(end_date);

    var start_date = new Date(0); // The 0 there is the key, which sets the date to the epoch
    start_date.setUTCSeconds(start_time);
    let startStrTime = timeConverter(start_date);

    let full_date = startStrTime + " - " + endStrTime;
    return full_date;
}

var scheduleEndTime = function(end_time) {
    var date = new Date(0); // The 0 there is the key, which sets the date to the epoch
    date.setUTCSeconds(end_time);
    let strTime = timeConverter(date);

    let full_date = strTime;
    return full_date;
}

var pastDateTime = function(current_epoch, start_time) {
    
    let difference = Math.floor((current_epoch - start_time)/60);
    let unit = "mins ago";

    if(difference>43200){
        difference = Math.floor(difference/43200);
        if(difference>1){
           unit = "months ago";
        }else{
            unit = "month ago" 
        }
    }else if(difference>10080){
        difference = Math.floor(difference/10080);
        if(difference>1){
           unit = "weeks ago";
        }else{
            unit = "week ago" 
        }
    }else if(difference>1440){
        difference = Math.floor(difference/1440);
        if(difference>1){
           unit = "days ago";
        }else{
            unit = "day ago" 
        }
    }else if(difference>60){
        difference = Math.floor(difference/60);
        if(difference>1){
           unit = "hours ago";
        }else{
            unit = "hour ago" 
        }
    }
    let full_date = difference + " " + unit;
    return full_date;
}

var timeConverter = function(date) {
 	let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    let strTime = hours + ':' + minutes + ' ' + ampm;

    return strTime;
}

var onlyTime = function(start_time) {
    var date = new Date(0); // The 0 there is the key, which sets the date to the epoch
    date.setUTCSeconds(start_time);
    let strTime = timeConverter(date);

    return strTime;
}

var onlyDate = function(start_time) {
    var date = new Date(0); // The 0 there is the key, which sets the date to the epoch
    date.setUTCSeconds(start_time);
    let month_array = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    let month_num = date.getMonth();
    var full_year = date.getFullYear();
    let month = month_array[month_num];
    let day = date.getDate();
    let strTime = timeConverter(date);

    let full_date = day + " " + month + " " + full_year;
    return full_date;
}

var subjectString = function(subjects) {
    let subject_string = "";
    for(let i = 0; i<subjects.length; i++){
        if(i!=0){
            subject_string = subject_string + " ";
        }
        subject_string = subject_string + subjects[i].name;
        if(i!=(subjects.length-1)){
            subject_string = subject_string + ",";
        }
    }

    return subject_string;
}

var extractUrlParam = function() {
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    var query_string = {};
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        var key = decodeURIComponent(pair[0]);
        var value = decodeURIComponent(pair[1]);
            // If first entry with this name
        if (typeof query_string[key] === "undefined") {
            query_string[key] = decodeURIComponent(value);
            // If second entry with this name
        } else if (typeof query_string[key] === "string") {
            var arr = [query_string[key], decodeURIComponent(value)];
            query_string[key] = arr;
            // If third or later entry with this name
        } else {
            query_string[key].push(decodeURIComponent(value));
        }
    }

    return query_string.payment_request_id;
}

var extractUrlParamSignup = function() {
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    var query_string = {};
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        var key = decodeURIComponent(pair[0]);
        var value = decodeURIComponent(pair[1]);
            // If first entry with this name
        if (typeof query_string[key] === "undefined") {
            query_string[key] = decodeURIComponent(value);
            // If second entry with this name
        } else if (typeof query_string[key] === "string") {
            var arr = [query_string[key], decodeURIComponent(value)];
            query_string[key] = arr;
            // If third or later entry with this name
        } else {
            query_string[key].push(decodeURIComponent(value));
        }
    }

    return query_string.first_time_signup;
}

/*
var setMetaTags = function setTitle(title, meta_desc) {
  if (typeof title !== "string") {
     throw new Error("Title should be an string");
  }
  document.title = title;
}
*/
/*
var Moengage = window.moe({
    app_id:"LC3RE8YF0NBWQUV6CVEES1CL",
    debug_logs: 0,
    enableSPA: true
});

exports.Moengage = Moengage;
*/
//exports.setMetaTags = setMetaTags;
exports.findGetParameter = findGetParameter;
exports.onlyTime = onlyTime;
exports.onlyDate = onlyDate;
exports.dayWeekTime = dayWeekTime;
exports.dateStartTime = dateStartTime;
exports.timeRange = timeRange;
exports.dateConverter = dateConverter;
exports.upcomingDateTime = upcomingDateTime;
exports.scheduleStartTime = scheduleStartTime;
exports.scheduleEndTime = scheduleEndTime;
exports.cancellationTime = cancellationTime;
exports.subjectString = subjectString;
exports.extractUrlParam = extractUrlParam;
exports.extractUrlParamSignup = extractUrlParamSignup;
