import React, { Component } from 'react';
import Header from '../Header';
import { Redirect } from 'react-router';

import Footer from '../Footer';

class Payment extends Component {
  	constructor(props) {
	  	super(props);
	  	var url_string = window.location.href; //window.location.href
		var url = new URL(url_string);
		var price = url.searchParams.get("price");
		var plan = url.searchParams.get("plan");
		var qty = url.searchParams.get("qty");
		var valid_date = url.searchParams.get("valid_date");
		var phone = url.searchParams.get("phone");

		if(qty==undefined){
			qty = 1;
		}

		if(valid_date==undefined){
			valid_date = null;
		}

		if(phone==undefined){
			phone = null;
		}

		var total_amount = price*qty;

	    this.state = {
	      price: price,
	      plan: plan,
	      valid_date: valid_date,
	      qty: qty,
	      total_amount: total_amount,
	      phone: phone
	    };

	    this.handleChange = this.handleChange.bind(this);
	    this.createOrder = this.createOrder.bind(this);
  	}

	handleChange(mob) {
		if(mob.target.value.length===10){
			document.getElementById('pay_btn'). style. backgroundColor = '#1D9929';
		}else{
			document.getElementById('pay_btn'). style. backgroundColor = '#717171';
		}
	    this.setState({
	      phone: mob.target.value
	    })
	}

	render(){
		return(
			<div>
				<Header update_token={this.props.update_token} current_route={this.props.current_route}  access_token={this.props.access_token} />
					<div className="price-page-container row">
					<div className="col-sm-12 col-xs-12 col-lg-12">
						<div className="static-intro">
							<h1>Purchase your plan and get started!</h1>
							{this.state.valid_date!=null ? <p>Price valid till {this.state.valid_date}</p> : <p></p> }
						</div>
						<div className="row pricing-section">
							<div className="col-sm-4 col-sm-offset-4 col-xs-12">
								<div className="item-pricing-page">
									<h3>{this.state.plan}</h3>
									<p>{this.state.price}₹</p>
									{this.state.qty>1 ? <p>No. of Kids: {this.state.qty}</p> : <p></p> }
									<input value={this.state.phone} onChange={this.handleChange} type="tel" name="mobile" className="mobile-login" placeholder="Your 10 digit phone number" minLength={10} maxLength={10} />
									<div className="btns-group pricing-page-book-btn">
										<div id="pay_btn" onClick={this.createOrder} className="btn btn-danger btn-lg has-shadow anchor btn-session whatsapp-us-btn extra-css-whatsapp pricing-page-btn-css">
											<span>Pay {this.state.total_amount}₹</span>
											<i className="fas fa-chevron-right float-right"></i>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<Footer />
			</div>
		);
	}

	createOrder(){
		var myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");
		myHeaders.append("Cookie", "XSRF-TOKEN=eyJpdiI6IjNIanJiaUg1RVpYUVg4Rk5IbUorVVE9PSIsInZhbHVlIjoiY3hcL001RlwvRVZkeGpodXd2SVhteUg4ZWVtcUZFVVU0NlUxRlR1NlF3dFJHelwvNjRhODA3M0pSYnh5SGU4QWNmOCIsIm1hYyI6ImY2ZDY1ODU4NzdmZjQxYzc1OTY0MmQ5YjYzZWViZTEzYjJhZmVlNDBmYjc3M2M3NzY4MjdiMjA1NDMxZWEwNjkifQ%3D%3D; laravel_session=eyJpdiI6Ims5TWxDdnpUNU5KdlNYZ3hib3RQcnc9PSIsInZhbHVlIjoiSEQ1aCthK0pjYXhkVEdmelB2UzFPVGNYY0xFZ2UxN0VBSkUzVVNaM1FJQ013WllKYUt4bFZOb1V3MFRGRkVkSyIsIm1hYyI6Ijc2YzBjYjhiNTExMDM2OTQ1OGJkNDZmOTg1ZWFhM2NkMGUzZjRiZDMwMWFjY2RlNDVkN2JlYWZmMTlhOTliYmIifQ%3D%3D");
		if(this.state.phone.length!=10){
			alert("Please enter valid 10 digit mobile number");
		}else{
			var raw = JSON.stringify({"phone":this.state.phone,"plan_name":this.state.plan,"referral":null,"amount":this.state.total_amount});

			var requestOptions = {
			  method: 'POST',
			  headers: myHeaders,
			  body: raw,
			  redirect: 'follow'
			};


			fetch(`https://api.dubskill.com/order`, requestOptions)
			  	.then((response) => {
				    if(response.ok) {
				    	return response.json();
				    }else{
				    	throw new Error('Something went wrong. Please contact support.');
				    	return Promise.reject();
				    }
				})
		        .then(
		          (result) => {
		          	//alert(result.success);
		          	window.location = result.data.payment_url;
		          }
	      		).catch(
	      			(error) => {
	      				console.log(error);
	      			}
	      		);
      	}
	}
}
//className="strike-it"
export default Payment;
