import React, { Component } from 'react';
import Header from '../Header';
import { Redirect } from 'react-router';

import Footer from '../Footer';

class PricePlans extends Component {
	render(){
		if (this.props.access_token===null) {
	      return <Redirect to='/login'/>;
	    }
		return(
			<div>
				<Header update_token={this.props.update_token} current_route={this.props.current_route}  access_token={this.props.access_token} />
					<div className="price-page-container row">
					<div className="col-sm-12 col-xs-12 col-lg-12">
						<div className="static-intro">
							<h1>Simple, straightforward pricing—a child’s play!</h1>
							<h2>Get started immediately. Pick a plan later!</h2>
						</div>
						<div className="row pricing-section">
							<div className="col-sm-3 col-xs-12">
								<div className="item-pricing-page">
									<h3>The Free One</h3>
									<h4>That’s how every great thing ever started.</h4>
									<p>INR 0 for 2 sessions</p>
									<div className="points-pricing-plan">
										<p><i className="fas fa-hand-point-right"></i> Try 2 sessions of any activity of your choice</p>
										<p><i className="fas fa-hand-point-right"></i> Get comprehensive feedback from our expert tutors</p>
									</div>
									<div className="btns-group pricing-page-book-btn">
										<a href="https://api.whatsapp.com/send?phone=+919036268716&text=Hi, I want to purchase the Free Plan."  target="_blank" className="btn btn-danger btn-lg has-shadow anchor btn-session whatsapp-us-btn extra-css-whatsapp pricing-page-btn-css">
											<span>Enquire Now</span>
											<i className="fas fa-chevron-right float-right"></i>
										</a>
									</div>
								</div>
							</div>
							<div className="col-sm-3 col-xs-12">
								<div className="item-pricing-page">
									<h3>1 month</h3>
									<h4>To start your journey</h4>
									<p>6490₹ per month</p>
									<div className="points-pricing-plan">
										<p><i className="fas fa-hand-point-right"></i> Unlimited sessions of all activities</p>
										<p><i className="fas fa-hand-point-right"></i> Progress tracking and regular feedback</p>
										<p><i className="fas fa-hand-point-right"></i> Beginner certification on meeting attendance goals</p>
									</div>
									<div className="btns-group pricing-page-book-btn">
										<a href="https://api.whatsapp.com/send?phone=+919036268716&text=Hi, I want to purchase the 1 month Plan."  target="_blank" className="btn btn-danger btn-lg has-shadow anchor btn-session whatsapp-us-btn extra-css-whatsapp pricing-page-btn-css">
											<span>Enquire Now</span>
											<i className="fas fa-chevron-right float-right"></i>
										</a>
									</div>
								</div>
							</div>
							<div className="col-sm-3 col-xs-12">
								<div className="item-pricing-page">
									<h3>3 months</h3>
									<h4>Most popular</h4>
									<p>4990₹ per month</p>
									<div className="points-pricing-plan">
										<p><i className="fas fa-hand-point-right"></i> Unlimited sessions of all activities</p>
										<p><i className="fas fa-hand-point-right"></i> Progress tracking and regular feedback</p>
										<p><i className="fas fa-hand-point-right"></i> Certification on meeting attendance goals</p>
										<p><i className="fas fa-hand-point-right"></i> A welcome kit with surprise goodies</p>
										<p><i className="fas fa-hand-point-right"></i> Can Freeze the plan for upto a month</p>
									</div>
									<div className="btns-group pricing-page-book-btn">
										<a href="https://api.whatsapp.com/send?phone=+919036268716&text=Hi, I want to purchase the 3 months Plan."  target="_blank" className="btn btn-danger btn-lg has-shadow anchor btn-session whatsapp-us-btn extra-css-whatsapp pricing-page-btn-css">
											<span>Enquire Now</span>
											<i className="fas fa-chevron-right float-right"></i>
										</a>
									</div>
								</div>
							</div>
							<div className="col-sm-3 col-xs-12">
								<div className="item-pricing-page">
									<h3>6 months</h3>
									<h4>For long term results</h4>
									<p>4490₹ per month</p>
									<div className="points-pricing-plan">
										<p><i className="fas fa-hand-point-right"></i> Unlimited sessions of all activities</p>
										<p><i className="fas fa-hand-point-right"></i> Progress tracking and regular feedback</p>
										<p><i className="fas fa-hand-point-right"></i> Certification on meeting attendance goals</p>
										<p><i className="fas fa-hand-point-right"></i> A welcome kit with surprise goodies</p>
										<p><i className="fas fa-hand-point-right"></i> Can Freeze the plan for upto 2 months</p>
										<p><i className="fas fa-hand-point-right"></i> Exposure at events and tournaments</p>
										<p><i className="fas fa-hand-point-right"></i> Monthly progress reports and dedicated parent-teacher meetings</p>
									</div>
									<div className="btns-group pricing-page-book-btn">
										<a href="https://api.whatsapp.com/send?phone=+919036268716&text=Hi, I want to purchase the 6 months Plan."  target="_blank" className="btn btn-danger btn-lg has-shadow anchor btn-session whatsapp-us-btn extra-css-whatsapp pricing-page-btn-css">
											<span>Enquire Now</span>
											<i className="fas fa-chevron-right float-right"></i>
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<Footer />
			</div>
		);
	}
}
//className="strike-it"
export default PricePlans;