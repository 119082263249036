import React, { Component } from 'react';
import {
  Link
} from "react-router-dom";
import { Redirect } from 'react-router';
import swal from 'sweetalert';
import Header from '../Header';
import EnterMobile from './EnterMobile';
import EnterOTP from './EnterOTP';

import Footer from '../Footer';

//var Moengage = require('../utils.js').Moengage;

class Login extends Component {

	constructor(props) {
	  	super(props);
	    this.state = {
	      showPhone: true,
	      mob: 0,
	      redirect: false, //redirects after login,
	      first_time_user: 0
	    };
	    this.mobHandler = this.mobHandler.bind(this);
	    this.otpHandler = this.otpHandler.bind(this);
	}

	mobHandler(mob){

		var myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");
		var raw = JSON.stringify({"phone":mob});

		var requestOptions = {
		  method: 'POST',
		  headers: myHeaders,
		  body: raw,
		  redirect: 'follow'
		};

		fetch(`https://dashboard.sosschoolofskills.com/send-otp`, requestOptions)
		  	.then((response) => {
			    if(response.ok) {
			    	return response.json();
			    }else{
			    	throw new Error('Something went wrong. Please contact support.');
			    	return Promise.reject();
			    }
			})
	        .then(
	          (result) => {
	          	//alert(result.success);
	          	this.setState({
			      showPhone: false,
			      mob: mob,
			      first_time_user: result.data.first_time_user
			    });
	          }
      		).catch(
      			(error) => {
      				console.log(error);
      			}
      		);
	}

	otpHandler(otp) {
		var myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");
		
		var raw = JSON.stringify({"phone":this.state.mob,"otp":otp});

		var requestOptions = {
		  method: 'POST',
		  headers: myHeaders,
		  body: raw,
		  redirect: 'follow'
		};

		fetch(`https://dashboard.sosschoolofskills.com/verify-otp`, requestOptions)
		  	.then(function(response) {
		  		let status = response.status;
		  		let json_response = response.json();
			    if (status != 200) {
			    	swal("OTP doesn't seem to be right. Please recheck.");
			        if(status === 400 || status === 404){
			        	console.log(json_response);
			        	throw new Error('Something went wrong. Please contact support.');
			        }else{
			        	throw new Error('Something went wrong. Please contact support.');
			        }
			    }else{
			    	return json_response;
			    }
			    
			})
	        .then(
	          (result) => {
	          	localStorage.setItem('student_id', result.data.user_id);
	          	localStorage.setItem('student_token', result.data.token);
	          	this.props.update_token(); 
	          	//this.setState({
			      //redirect: true
			    //});
			    window.location = "/price-plans";
	          },
	          (error) => {
	          	//alert(error);
	          }
      		);
	}

	render(){
		if (this.state.redirect) {
	       return <Redirect to='/'/>;
	    }

		return(
			<div>
				<div className="row login-page">
					<div className="login-box col-sm-6 col-md-6 col-lg-6 col-xs-12">
						<div className="login-img-div">
							<img src="/img/logo.png" className="big-logo-size" />
						</div>
						<h1>Sign up/Sign in</h1>
						<p>Please login to check our pricing plans.</p>
						{ this.state.showPhone ? <EnterMobile mob_handler={this.mobHandler} /> : <EnterOTP otp_handler={this.otpHandler} mob_number={this.state.mob} first_time_user={this.state.first_time_user} /> }
					</div>
					<div className="col-sm-6 intro-area full-height hidden-xs">
							<div className="intro-right-content login-page-right">
								<h1>All online + offline extracurricular activities</h1>
								<h2>In one plan for ages 6-15</h2>
							</div>
						</div>
	            </div>
            </div>
			);
	}
}


export default Login;
