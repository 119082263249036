import React, { Component } from 'react';

class Footer extends Component {

	render(){
		return(
			<footer>
				<div className="footer">
					<section className="container generic-section">
						<div className="row-fluid">	
			            	<div className="col-sm-3 item left">
				    			<h3>About Us</h3>
				    			<p>Every amazing activity is under one simple plan. Never boring, you get to mix and match every day, attend sessions you want to, and learn at your own sweet pace. While we make sure, you progress in each session through our adaptive curriculums/lesson plan.</p>
				    			<p>One plan. Multiple activities. No boredom.</p>
			    			</div>  	
			
				    		<div className="col-sm-3 item left">
				    			<h3>Contact Us</h3>
								<p><i className="fas fa-envelope-open-text"></i> <strong>Email:</strong>  <a href="mailto:hello@dubskill.com?subject=feedback">hello@dubskill.com</a></p>
								<br />
								<p><i className="fas fa-mobile"></i> <strong>Tel:</strong> +91-9036268716</p>
								<br />
								<p><i className="fas fa-map-marker"></i> <strong>Koramangala Address:</strong>  Ground Floor, 736, 7th Cross Rd, Koramangala 3 Block, Koramangala (Near Koramangala BDA Complex), Bengaluru, Karnataka 560034</p>
				    			<p><i className="fas fa-map-marker"></i> <strong>HSR Address:</strong>  1215, 22nd Cross Rd, Sector 3, HSR Layout, Bengaluru, Karnataka 560102</p>
				    		</div>  	
			                	
				    		<div className="col-sm-3 item right quick-links">
				    			<h3>Super Quick Links</h3>
								<a href="/#gallery">+ Activities</a>
								<br />
								<a href="/#cool-things">+ Features</a>
								<br />
								<a href="/#team">+ Instructors</a>
								<br />
								<a href="privacy-policy">+ Privacy Policy</a>
								<br />
								<a href="refund-policy">+ Refund & Cancellation Policy</a>
								<br />
								<a href="terms-and-conditions">+ Terms & Conditions</a>
								<br />
								<a href="contact-us">+ Contact Us</a>
				    		</div>  
			              
				    		<div className="col-sm-3 item right">
				    			<h3>Stay in Touch!</h3>
				    			<p>Follow US on Facebook & Instagram!<br /></p>
								<ul className="social-foot">
									<a href="https://www.facebook.com/wearedubskill/"><i className="fab fa-facebook footer-social-icons"></i></a>
									<a href="https://www.instagram.com/wearedubskill/"><i className="fab fa-instagram footer-social-icons left-margin-10"></i></a>
								</ul>
				    		</div>  		
						</div>
					</section>
				</div>
				 <div className="copy">
				 	<a href="/#top" className="anchor totop">to top</a>
			     	<section className="container np">
						<p>Copyright © 2022 Alloy Labs. All Rights Reserved.</p>
					</section>
				</div>
			</footer>
			);
	}
}

export default Footer;