import React, { Component } from 'react';
import Header from '../Header';
import { Redirect } from 'react-router';

import Footer from '../Footer';
var findGetParameter = require('../utils.js').findGetParameter;

class ContactUs extends Component {
	render(){
		var header_footer = findGetParameter("header");
		return(
			<div>
			{header_footer!=0 ? <Header update_token={this.props.update_token} current_route={this.props.current_route}  access_token={this.props.access_token} /> : (<div></div>)}
					<div className="static-page-container row">
					<div className="col-sm-12 col-xs-12 col-lg-12">
						<h1 className="static-page-heading">Contact Us</h1>
						<p>Please WhatsApp Us at +91-9036268716</p>
					</div>
				</div>
			{header_footer!=0 ? <Footer /> : (<div></div>) }
			</div>
		);
	}
}

export default ContactUs;