import React, { Component } from 'react';

class VideoSection extends Component {

	render(){
		return(
				    <section id="team">
				    	<div className="container text-center">
				    		<h2>Performance Videos</h2>
				    		<div className="container-carousel">
				    			<div className="col-sm-4 col-xs-12 padding-yt">
									<div className="item">
								  		<div className="thumb">
								  			<figure>
												<iframe className="yt-iframe" width="560" height="315" src="https://www.youtube.com/embed/yArderrfuro?si=knec0sXGsZg_0FAo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
											</figure>
										</div>
										<h3>Nikhil - 6th Grade</h3>
									
									</div>
								</div>
								<div className="col-sm-4 col-xs-12 padding-yt">
									<div className="item">
								  		<div className="thumb">
								  			<figure>
												<iframe className="yt-iframe"  width="560" height="315" src="https://www.youtube.com/embed/__Z1r3igvpk?si=oJXfANB2A52ZoX-5" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
											</figure>
										</div>
										<h3>Myra - 5th Grade</h3>
										
									</div>
								</div>
								<div className="col-sm-4 col-xs-12 padding-yt">
									<div className="item">
								  		<div className="thumb">
								  			<figure>
												<iframe className="yt-iframe" width="560" height="315" src="https://www.youtube.com/embed/8cCxX7g41L8?si=7w9g0zTddrrzgU7w" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
											</figure>
										</div>
										<h3>Avika - 5th Grade </h3>
										
									</div>
								</div>
							</div>
								
					    </div>
				    </section>	
			);
	}
}

export default VideoSection;