import React, { Component } from 'react';
import {
  Link
} from "react-router-dom";
import { Redirect } from 'react-router';
import OtpInput from 'react-otp-input';
import swal from 'sweetalert';
import Header from '../Header';

//var Moengage = require('../utils.js').Moengage;

class Logout extends Component {

	constructor(props) {
	  	super(props);
	    this.state = {
	    	redirect_to_login: false
	    };
	 }

	componentDidMount() {
		localStorage.removeItem("student_token");
		localStorage.removeItem("student_id");
		this.props.update_token();
		this.setState({redirect_to_login: true});
	}

	render(){
		return(
			this.state.redirect_to_login ? (
				<Redirect to="/login" />
			) : (
			<div>
				Logging Out...
            </div>
            )
			);
	}
}



export default Logout;
