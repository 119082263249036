import React, { Component } from 'react';
import Header from '../Header';
import { Redirect } from 'react-router';

import Footer from '../Footer';
var findGetParameter = require('../utils.js').findGetParameter;

class PrivacyPolicy extends Component {
	render(){
		var header_footer = findGetParameter("header");
		return(
			<div>
				{header_footer!=0 ? <Header update_token={this.props.update_token} current_route={this.props.current_route}  access_token={this.props.access_token} /> : (<div></div>)}
					<div className="static-page-container row">
						<div className="static-page-container row">
							<div className="col-sm-12 col-xs-12 col-lg-12">
								<h1 className="static-page-heading">Privacy Policy</h1>
								<p>Dubskill respects your privacy and recognizes the need to protect the personally identifiable information (any information by which you can be identified, such as name, address, and telephone number) you share with us. We would like to assure you that we follow appropriate standards when it comes to protecting your privacy on our web site.</p>

								<p>In general, you can visit Dubskill website without telling us who you are or revealing any personal information about yourself. We track the Internet address of the domains from which people visit us and analyze this data for trends and statistics, but the individual user remains anonymous. Some of our web pages use cookies so that we can better serve you with customized information when you return to our site. Cookies are identifiers which a web site can send to your browser to keep on your computer to facilitate your next visit to our site. You can set your browser to notify you when you are sent a cookie, giving you the option to decide whether or not to accept it. The information we collect and analyze is used to improve our service to you.</p>

								<p>There are times when we may collect personal information from you such as name, email address, physical address or telephone number. It is our intent to inform you before we do that and to tell you what we intend to do with the information. If you choose not to provide the information we request, you can still visit the Dubskill website/app, but you may be unable to access certain options, offers and services.</p>

								<p>Dubskill will not sell, trade or disclose to third parties any information derived from the registration for, or use of, any online service (including names and addresses) without the consent of the user or customer (except as required by subpoena, search warrant, or other legal process or in the case of imminent physical harm to the user or others). Dubskill will allow suppliers to access the information only for purposes of confirming your registration and providing you with benefits you are entitled to.</p>

								<p>Dubskill will take appropriate steps to protect the information you share with us. We have implemented technology and security features and strict policy guidelines to safeguard the privacy of your personally identifiable information from unauthorized access and improper use or disclosure. Dubskill will continue to enhance our security procedures as new technology becomes available.</p>

								<p>Dubskill may use pictures clicked at the events/parties/activities of its partner organiser/venue on its website, app and digital marketing channels after due permission from the organiser/venue. In case you wish to avoid such usage, you can deny clicking of photographs at such events/parties/activities. Also, if your photos have been featured on our website, app or digital marketing channels and you wish to have it removed, you can place a request on our support channels to have it removed.</p>

								<p>If our privacy policy changes in the future, it will be posted here and a new effective date will be shown. You should access our privacy policy regularly to ensure you understand our current policies.</p>
							</div>
						</div>
					</div>
				{header_footer!=0 ? <Footer /> : (<div></div>) }
			</div>
		);
	}
}

export default PrivacyPolicy;