import React, { Component } from 'react';

class SocietyRegister extends Component {

	render(){
		return(
			<section id="gallery" className="bg-color">
					<div className="container text-center">
						<h2> Want classes in your society?</h2>
						<div className="row">
							<h4 className="sos-activity-h">You can get started in 3 simple steps. Simply click on the link below, send us a message and attend the trial session at your society!</h4>
							
						<center>
							<div className="btns-group">
								<a href="https://api.whatsapp.com/send?phone=+919036268716&text=Hi, Let me know how to book a free session with Dubskill at my society."  target="_blank" className="btn btn-danger btn-lg has-shadow anchor btn-session whatsapp-us-btn extra-css-whatsapp">
									<span>Book a free session</span>
									<i className="fas fa-chevron-right float-right"></i>
								</a>
							</div>
						</center>
						</div>
					</div>
			</section>
			);
	}
}

export default SocietyRegister;