import React, { Component } from 'react';

class FeatureSection extends Component {

	render(){
		return(
			<section id="features" className="bg-color">
				<div className="container">
					<h2>What sets Dubskill apart</h2>
					<div className="row">
						<div className="col-sm-3 item">
							<div className="icon">
								<figure>
									<img src="./img/ice_skating.svg" alt="//" className="bit-img-padding" />
								</figure>
							</div>
							<h3>Certifications & Competitions</h3>
							<p>Stage performance every 3 months and certifications based on levels.</p>
						</div>
						<div className="col-sm-3 item">
							<div className="icon">
								<figure>
									<img src="./files/ico-43.png" alt="//" />
								</figure>
							</div>
							<h3>Daily Reports</h3>
							<p>A quick summary after every class in your inbox.</p>
						</div>
						<div className="col-sm-3 item">
							<div className="icon">
								<figure>
									<img src="./img/sick.png" alt="//" />
								</figure>
							</div>
							<h3>Compensatory classes</h3>
							<p>Regular compensatory classes to ensure the kids do not miss out.</p>
						</div>
						<div className="col-sm-3 item">
							<div className="icon">
								<figure>
									<img src="./img/loudspeaker.svg" alt="//" />
								</figure>
							</div>
							<h3>Practical learning</h3>
							<p>Mastery through speech practice and performance in every class.</p>
						</div>
					</div>
					
				</div>
			</section>
			);
	}
}

export default FeatureSection;