import React, { Component } from 'react';
import Header from '../Header';
import { Redirect } from 'react-router';

import Footer from '../Footer';
var findGetParameter = require('../utils.js').findGetParameter;

class TermsConditions extends Component {
	render(){
		var header_footer = findGetParameter("header");
		return(
			<div>
				{header_footer!=0 ? <Header update_token={this.props.update_token} current_route={this.props.current_route}  access_token={this.props.access_token} /> : (<div></div>)}
				<div className="static-page-container row">
					<div className="col-sm-12 col-xs-12 col-lg-12">
						<h1 className="static-page-heading">Terms and Conditions</h1>
						<p>Dubskill is a trademark of Alloy Labs, a partnership firm registered on the address - Ground Floor, 736, 7th Cross Rd, Koramangala 3 Block, Koramangala (Near Koramangala BDA Complex), Bengaluru, Karnataka 560034. For all queries, please write to hello@dubskill.com. </p>
						<ol>
							<li>A student will have access to all the activities and sessions facilitated by Dubskill for the entire tenure of the enrollment. </li>
							<li>A booking must be made for attending a session through the Dubskill website/app or by contacting Dubskill’s official Whatsapp number. Confirmation SMS/Email for the session is mandatory to attend.</li>
							<li>A booking for a session must be made before the session's scheduled start. </li>
							<li>The sessions are available on a first-come-first-serve basis. If all the slots of a particular session are booked, further booking options will be disabled.</li>
							<li>In case a student is unable to attend a session after booking it, the booking should be canceled at least 2 hours before the start of the session. Cancellation can be done through Dubskill app or by contacting Dubskill through WhatsApp/email. If a booking is not canceled and the student does not attend the session, one day will be reduced from students plan validity as penalty.</li>
							<li>A student must reach the premises at least 5 minutes before the scheduled start of the class. </li>
							<li>A student can attend a maximum of 2 sessions per day.</li>
							<li>In case of unforeseen circumstances, Dubskill reserves the right to cancel or reschedule the session. </li>
							<li>Dubskill reserves the right to admission to students for all activities and sessions on non-compliance with the set guidelines and instructions.  </li>
							<li>Dubskill can add new activities and remove existing activities based on parent feedback and demand. </li>
							<li>Dubskill can change the venue of indoor and outdoor activities by giving prior notice to the parents.</li>
							<li>Dubskill reserves the right to increase or decrease the number of sessions per activity and alter the timings of the sessions to manage the schedule.</li>
							<li>Dubskill reserves the right to change the teachers for one and all the activities and provide substitute teachers on the non-availability of regular teachers.</li>
							<li>All communication between the parent/student and teachers will be under Dubskill’s supervision. </li>
							<li>Dubskill reserves the right to change the pricing plan and fee structure after completing the existing enrollment tenure. </li>
							<li>
								Dubskill will provide the option to freeze the plan, depending on the enrolled plan:
								<ul>
									<li>For a 1-month plan - The freeze option can be exercised a maximum of 1 time, the duration of which should not exceed 10 days.</li>
									<li>For a 3-month plan - The freeze option can be exercised a maximum of 2 times, the duration of which should not exceed 20 days each. </li>
									<li>For a 6-month plan - The freeze option can be exercised a maximum of 2 times, the duration of which should not exceed 30 days each. </li>

								</ul>
							</li>
							<li>
								Dubskill's Referral Policy will be subject to following:
								<ul>
									<li>The referral will be accounted for only upon successful enrollment.</li>
									<li>Valid only for first 3 successful referrals. </li>
									<li>Parents cannot refer existing leads and contacts of Dubskill. The policy will only be applicable for referring new users to Dubskill.</li>
								</ul>
							</li>
							<li>All other terms and conditions mentioned on the website apply.</li>
						</ol>	
					</div>
				</div>
				{header_footer!=0 ? <Footer /> : (<div></div>) }
			</div>
		);
	}
}

export default TermsConditions;