import React, { Component } from 'react';
import Header from '../Header';
import { Redirect } from 'react-router';

import Footer from '../Footer';
var findGetParameter = require('../utils.js').findGetParameter;

class RefundPolicy extends Component {
	render(){
		var header_footer = findGetParameter("header");
		return(
			<div>
				{header_footer!=0 ? <Header update_token={this.props.update_token} current_route={this.props.current_route}  access_token={this.props.access_token} /> : (<div></div>)}
					<div className="static-page-container row">
						<div className="static-page-container row">
							<div className="col-sm-12 col-xs-12 col-lg-12">
								<h1 className="static-page-heading">Refund & Cancellation Policy</h1>
								<ol>
									<li>In all our plans, the refund will be applicable on pro rata basis in case you wish to discontinue.</li>
									<li>The refund will be credited to parents account within 30 days after cancelling the plan.</li>
									<li>A written email has to be sent to hello@dubskill.com to cancel your plan.</li>
									<li>An extra 10% cancellation fee (10% of your plan value) will be deducted before processing your refund.</li>
								</ol>
							</div>
						</div>
					</div>
				{header_footer!=0 ? <Footer /> : (<div></div>) }
			</div>
		);
	}
}

export default RefundPolicy;