import React, { Component } from 'react';
import Header from '../Header';
import { Redirect } from 'react-router';

import Footer from '../Footer';

class PaymentReturn extends Component {
  	constructor(props) {
	  	super(props);
	  	var url_string = window.location.href; //window.location.href
		var url = new URL(url_string);
		var order_id = url.searchParams.get("cf_id");

	    this.state = {
	      order_id: order_id,
	      success: false,
	      loading: 0
	    };
  	}

	componentDidMount(){
		//alert("hey");
		let url = "https://api.dubskill.com/order/"+this.state.order_id;
		var myHeaders = new Headers();

		var requestOptions = {
		  method: 'GET',
		  headers: myHeaders,
		  redirect: 'follow'
		};

		fetch(url, requestOptions)
		  .then(function(response) {
	        let status = response.status;
	        let json_response = response.json();
	        return json_response;
	      })
	      .then(
	        (result) => {
	          console.log(result);
	          this.setState({
	            success: result.success,
	            loading: 1,
	            amount: result.data.amount
	          });
	        },
	        (error) => {
	          console.log(error);
	        }
	      );
	}

	render(){
		return(
			<div>
				<Header update_token={this.props.update_token} current_route={this.props.current_route}  access_token={this.props.access_token} />
					<div className="price-page-container row">
					<div className="col-sm-12 col-xs-12 col-lg-12">
						<div className="static-intro">
							{this.state.loading===0 ? <h1>Loading...</h1> : this.state.success===true ? <h1>Your Order is Successful</h1> : <h1>Your order couldn't be confirmed.</h1>}
						</div>
						<div className="row pricing-section">
							<div className="col-sm-4 col-sm-offset-4 col-xs-12">
								{this.state.loading===1 && this.state.success===true ?
								<div>
									<p>Order ID: {this.state.order_id}</p>
									<p>Amount Paid: {this.state.amount}</p>
								</div>
								: <p></p> }
							</div>
						</div>
					</div>
				</div>
				<Footer />
			</div>
		);
	}
}
//className="strike-it"
export default PaymentReturn;
