import React, { Component } from 'react';
import {
  Link
} from "react-router-dom";
import ReactDOM from 'react-dom';
import swal from 'sweetalert';
import LoginPopup from './Auth/LoginPopup';

class Header extends Component {

	showLoginPopup(){
		if(this.props.access_token==null || this.props.access_token==undefined){
			//alert("Please login to register.");
			//window.location.href = '/login';
			var swal_popup = document.createElement('div');
            ReactDOM.render(
              <React.StrictMode>
                <LoginPopup update_token={this.props.update_token} />
              </React.StrictMode>,
              swal_popup
            );
            //swal_welcome_content.innerHTML = <QnaPopup />;
            swal({
                "content": swal_popup,
                "buttons": false,
                "classNameName": "swal-login",
                "closeOnClickOutside": false
            });
		}
	}

	render(){

		let access_token = null;
		if(this.props.access_token){
			access_token = this.props.access_token;
		}

		return(
		<header className="fixed">
			<div className="container">
				  <div className="sos-header">
				    <a id="logo" className="navbar-brand" href="/"><img src="/img/new_logo.png" alt="My Brand" className="logo-image" /><span>ubskill</span></a>
				  </div>
				  
			</div>
	    </header>
			);
	}
}

/*
{this.props.access_token === null ? (
								<div className="header-login">
								    <Link to="/login"><button>Login</button></Link>
								</div>
							) : ( 
							<div className="avatar-header-div">
								<div className="inner-avatar-header-div">
									<img src="/student.png" alt="User Avatar" className="avatar-icon" />
									<div class="dropdown-content">
								      <Link to="/price-plans"><div class="desc">Pricing</div></Link>
								      <Link to="/logout"><div class="desc">Logout</div></Link>
								    </div>
							    </div>
						    </div>
						    )}
						    */

export default Header;
