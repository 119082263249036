import React, { Component } from 'react';
import Header from '../Header';
import { Redirect } from 'react-router';
import IntroSection from './IntroSection';
import FeatureSection from './FeatureSection';
import SocietyRegister from './SocietyRegister';
import USPSection from './USPSection';
import Testimonials from './Testimonials';
import VideoSection from './VideoSection';
//import Banner from './Banner';

import Footer from '../Footer';

class Home extends Component {
	render(){
		return(
			<div>
				<Header update_token={this.props.update_token} current_route={this.props.current_route} avatar_url={this.props.avatar_url} access_token={this.props.access_token} />
				<div>
					<IntroSection access_token={this.props.access_token} />
					<Testimonials />
    				<FeatureSection />
					<SocietyRegister />
					<USPSection />
					<VideoSection />
					<div className="whatsapp-btn-div">
						<a href="https://api.whatsapp.com/send?phone=+919036268716&text=Hi, Please tell me more about Dubskill." className="float-whatsapp" target="_blank">
						<i className="fa fa-whatsapp my-float-whatsapp"></i>
						</a>
					</div>
   				</div>
				<Footer />
			</div>
		);
	}
}

export default Home;