import React, { Component } from 'react';

class IntroSection extends Component {

	render(){
		let url="/price-plans";
		if (this.props.access_token===null) {
	      url = "/login";
	    }
		return(
			<section id="intro" className="intro-style intro-area">
					<div className="row">
						<div className="col-sm-6 clearfix">
							<img src="./img/main_banner_withoutbg.png" className="img-responsive img-intro" alt="img intro" />
						</div>
						<div className="col-sm-6">
							<div className="intro-right-content">
								<h1>Public Speaking & Debating Classes in Your School & Society</h1>
								<h2>Nurturing Great Speakers and Dynamic Debaters!</h2>
								<div className="btns-group clearfix">
									<a href={url} className="btn btn-danger btn-lg has-shadow anchor btn-session whatsapp-us-btn">
										<span>Age group - 8 to 13 years</span>
										<i className="fas fa-angle-double-right fa-fade float-right"></i>
									</a>
								</div>
							</div>
						</div>
					</div>
			</section>
			);
	}
}
//<p>Starting at just <span className="big-and-yellow">2990 INR</span> per month</p>

export default IntroSection;