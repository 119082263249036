import React, { Component } from 'react';

class Testimonials extends Component {

	render(){
		return(
				    <section id="team">
				    	<div className="container text-center">
				    		<h2>Testiomonials</h2>
				    		<div className="container-carousel">
				    			<div className="col-sm-12 col-xs-12">
									<div className="item">
								  		<div className="col-sm-4 col-xs-12">
								  			<figure>
												<img data-type="mText" src="./img/masters/yudit.png" className="img-responsive testimonial-img" alt="//" />
											</figure>
										</div>
										<h3>Ashima (Yudit’s mother)</h3>
										<p>My son Yudit has been going to Dubskill classes for more than a year. The classes have been instrumental in making him an extremely confident speaker. The classes are designed to learn both the theory and practical aspects of effective speeches. The teacher makes it very fun while in the process teaching advanced skills. The program is very comprehensive and covers all aspects of public speaking and eventually getting into debating techniques. Yudit has been winning school competitions and surprising his teachers with strong communication skills, all thanks to Dubskill!</p>
									</div>
								</div>
								<div className="col-sm-12 col-xs-12">
									<div className="item">
								  		<div className="col-sm-4 col-xs-12">
								  			<figure>
												<img data-type="mText" src="./img/masters/ekta.png" className="img-responsive testimonial-img" alt="//" />
											</figure>
										</div>
										<h3>Ekta (Navya’s mother)</h3>
										<p>Navya is attending Dubskill classes for about 1 year. We could observe improvement in her speech delivery within 1 month of her joining. She gained confidence, improved voice modulation, and improved pronunciation due to classes. Having gone through these classes has created a strong base for my daughter and will go a long way in her life as a public speaker. Thanks to these classes, she has won few competitions in the society. I would seriously recommend parents to consider Dubskill classes for their kids.</p>
									</div>
								</div>
								<div className="col-sm-12 col-xs-12">
									<div className="item">
								  		<div className="col-sm-4 col-xs-12">
								  			<figure>
												<img data-type="mText" src="./img/masters/saanvi.png" className="img-responsive testimonial-img" alt="//" />
											</figure>
										</div>
										<h3>Mittal Patel (Shivesh and Saanvi’s Mother)</h3>
										<p>Saanvi and Shivesh have been attending Dubskill public speaking classes since September 2022. I see significant improvement in their speaking abilities and overall confidence level. Recently Saanvi gave a speech in the class to run for a representative of her grade and won. I see a lot of improvements in Shivesh as he participates more in class discussions and speaks up with confidence. They really miss in-person classes due to our relocation but I am happy that they can continue Dubskill classes online. They always enjoy the class and I always hear positive things about the class and teacher. </p>
									</div>
								</div>
							</div>
								
					    </div>
					    			<center>
										<div className="btns-group">
											<a href="https://api.whatsapp.com/send?phone=+919036268716&text=Hi, I wish to book a session at School of Skills."  target="_blank" className="btn btn-danger btn-lg has-shadow anchor btn-session whatsapp-us-btn extra-css-whatsapp">
												<span>Book a free session</span>
												<i className="fas fa-chevron-right float-right"></i>
											</a>
										</div>
									</center>
				    </section>	
			);
	}
}

export default Testimonials;