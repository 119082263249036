import React, { Component } from 'react';

class USPSection extends Component {

	render(){
		return(
					<section id="cool-things" className="bg-color">
						<div className="container">
							<h2 className="text-center">What sets Dubskill apart</h2>
			   				<div className="row">
			   					<div className="col-sm-6">
				   					<div className="item right-icon clearfix">
				   						<div className="icon img-circle">
				   							<img src="./img/icons/flexible.png" className="icon-features" />
				   						</div>
			   							<div className="cont">
				   							<h3>Flexibility</h3>
				   							<p>For the first time ever, you can choose any activity any day without getting stuck with one.</p>
			   							</div>
				   					</div> 
				   				</div>	
			   					<div className="col-sm-6">
			   						<div className="item left-icon">
				   						<div className="icon img-circle">
				   							<img src="./img/icons/adaptive.png" className="icon-features" />
				   						</div>
			   							<div className="cont">
				   							<h3>Adaptive lesson plan</h3>
				   							<p>A flexible and adaptive curriculum/plan matches your learning style and schedule.</p>
			   							</div>
				   					</div>
			   					</div>
			   					
			   					<div className="col-sm-6">
				   					<div className="item right-icon clearfix">
				   						<div className="icon img-circle">
				   							<img src="./img/icons/track.png" className="icon-features" />
				   						</div>
			   							<div className="cont">
				   							<h3>Progress tracking</h3>
				   							<p>Track how far you are along in your ongoing activities and accordingly set and achieve goals.</p>
			   							</div>
				   					</div> 
				   				</div>	
			   					
			   					<div className="col-sm-6">
			   						<div className="item left-icon">
				   						<div className="icon img-circle">
				   							<img src="./img/icons/update.png" className="icon-features" />
				   						</div>
			   							<div className="cont">
				   							<h3>LIVE updates</h3>
				   							<p>Parents get LIVE updates about their kids' whereabouts, ensuring safety. </p>
			   							</div>
				   					</div>
			   					</div>
			   					
			   					<div className="col-sm-6">
				   					<div className="item right-icon clearfix">
				   						<div className="icon img-circle">
				   							<img src="./img/icons/development.png" className="icon-features" />
				   						</div>
			   							<div className="cont">
				   							<h3>Holistic development</h3>
				   							<p>A range of indoor and outdoor activities ensures your complete mental and physical growth.</p>
			   							</div>
				   					</div> 
				   				</div>	
			   					
			   					<div className="col-sm-6">
			   						<div className="item left-icon">
				   						<div className="icon img-circle">
				   							<img src="./img/icons/practical.png" className="icon-features" />
				   						</div>
			   							<div className="cont">
				   							<h3>Practical learning</h3>
				   							<p>Learn in a super-practical and fun way from the top experts in the field.</p>
			   							</div>
				   					</div>
			   					</div>
			   				</div>
			  			</div>
					</section>
			);
	}
}

export default USPSection;